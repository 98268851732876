<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="filters.name"
            v-on:keyup="search"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索飾金產品"
          />
        </div>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
        <b-button
          @click="showCreateModal"
          class="btn btn-secondary btn--h46px justify-content-center w-100"
        >
          新創建
        </b-button>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          @input="search"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          @input="search()"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <TableData
      :items="goldPrices.content"
      :headers="headers"
      :isLoading="isLoading"
    >
      <template v-slot:custom-currency="{ item }">
        <div
          v-if="item.currency == 'RMB'"
          class="d-flex align-items-center justify-content-center"
        >
          <IconYuan class="mr-2" />
          <small>人民幣</small>
        </div>
        <div
          v-else-if="item.currency == 'HKD'"
          class="d-flex align-items-center justify-content-center"
        >
          <IconHongKongDollar class="mr-2" />
          <small>港元</small>
        </div>
      </template>
      <template v-slot:custom-active="{ item }">
        <div v-if="item.active == true" class="text-success">啟用</div>
        <div v-else-if="item.active == false" class="text-danger">停用</div>
      </template>
      <template v-slot:custom-createdDate="{ item }">
        {{ item.createdDate | formatDate }}
      </template>
      <template v-slot:custom-action="{ item }">
        <b-dropdown
          variant="link"
          toggle-class="btn-sm text-dark text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon icon="three-dots" font-scale="1.5"></b-icon>
          </template>

          <b-dropdown-item @click="showEditModal(item.id)"
            >編輯</b-dropdown-item
          >
          <b-dropdown-item
            :disabled="item.active == false"
            v-on:click="handleDeactivate(item.id)"
          >
            停用
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="item.active == true"
            v-on:click="handleActivate(item.id)"
          >
            啟用
          </b-dropdown-item>
          <b-dropdown-item v-on:click="handleDelete(item.id)">
            刪除
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </TableData>

    <nav aria-label="goldPrices pagination">
      <ul class="pagination">
        <li class="page-item" :class="{ 'disabled active': goldPrices.first }">
          <a
            v-on:click="handlePaginate(goldPrices.number - 1)"
            class="page-link"
            href="#"
          >
            以前的
          </a>
        </li>
        <li
          v-for='pageNo in goldPrices.totalPages'
          :key="pageNo"
          class="page-item"
          v-bind:class="{ 'disabled active': ((pageNo - 1) === goldPrices.number) }"
        >
          <a
            v-on:click="handlePaginate(pageNo - 1)"
            class="page-link"
            href="#"
          >{{ pageNo }}</a>
        </li>

        <li class="page-item" :class="{ 'disabled active': goldPrices.last }">
          <a
            v-on:click="handlePaginate(goldPrices.number + 1)"
            class="page-link"
            href="#"
          >
            下一頁
          </a>
        </li>
      </ul>
    </nav>

    <!-- Create Gold News Modal -->
    <b-modal ref="createGoldNewsModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-form @submit.stop.prevent="onSubmitCreateGoldNews" class="p-3 p-lg-5">
        <b-alert show variant="danger" v-if="veeErrors.all().length">
          <ul class="list-unstyled mb-0">
            <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
          </ul>
        </b-alert>

        <h5 class="text-brown">創建產品系列</h5>

        <div class="mb-5">
          <div class="d-flex align-items-center justify-content-end mb-3">
            <div class="mr-2">啟用</div>
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="createFormControl.active"
              :checked="createFormControl.active"
            ></b-form-checkbox>
          </div>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhHk" class="text-brown">飾金產品 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhHk"
                class="rounded-0"
                name="nameZhHk"
                v-model="createFormControl.nameZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('nameZhHk')"
                aria-describedby="input-nameZhHk-live-feedback"
                data-vv-as="nameZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhCn" class="text-brown">饰金产品 (简体版本)</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhCn"
                class="rounded-0"
                name="nameZhCn"
                v-model="createFormControl.nameZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('nameZhCn')"
                aria-describedby="input-nameZhCn-live-feedback"
                data-vv-as="nameZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="inputPassword" class="text-brown">貨幣 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <v-select
                label="title"
                name="currency"
                :options="currencies"
                :reduce="currency => currency.currency"
                v-bind:class="{ 'border border-danger': veeErrors.has('currency') }"
                v-model="createFormControl.currency"
                v-validate="{ required: true }"
                :state="validateState('currency')"
                aria-describedby="input-currency-live-feedback"
                data-vv-as="currency"
              >
                <template slot="option" slot-scope="option" class="form-select">
                  <span class="mr-2" v-html="option.icon"></span>
                  {{ option.title }}
                </template>
              </v-select>

              <small class="d-block text-danger" id="input-currency-live-feedback" v-if="veeErrors.has('currency')">{{ veeErrors.first('currency') }}</small>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="buyPrice" class="text-brown">買入 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="buyPrice"
                class="rounded-0"
                name="buyPrice"
                v-model="createFormControl.buyPrice"
                v-validate="{ required: true, decimal:2, max: 100 }"
                :state="validateState('buyPrice')"
                aria-describedby="input-buyPrice-live-feedback"
                data-vv-as="buyPrice"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="sellPrice" class="text-brown">賣出 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="sellPrice"
                class="rounded-0"
                name="sellPrice"
                v-model="createFormControl.sellPrice"
                v-validate="{ required: true, decimal:2, max: 100 }"
                :state="validateState('sellPrice')"
                aria-describedby="input-sellPrice-live-feedback"
                data-vv-as="sellPrice"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-3">
          <b-col sm="4">
            <label for="posId" class="text-brown">發送通知</label>
          </b-col>
        </b-row>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideCreateModal()" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">儲存</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Gold News Modal -->
    <b-modal ref="editGoldNewsModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitUpdateGoldNews" class="p-3 p-lg-5">
        <h5 class="text-brown">創建產品系列</h5>

        <div class="mb-5">
          <div class="d-flex align-items-center justify-content-end mb-3">
            <div class="mr-2">啟用</div>
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="updateFormControl.active"
              :checked="updateFormControl.active"
            ></b-form-checkbox>
          </div>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhHk" class="text-brown">飾金產品 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhHk"
                class="rounded-0"
                name="nameZhHk"
                v-model="updateFormControl.nameZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('nameZhHk')"
                aria-describedby="input-nameZhHk-live-feedback"
                data-vv-as="nameZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhCn" class="text-brown">饰金产品 (简体版本)</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="nameZhCn"
                class="rounded-0"
                name="nameZhCn"
                v-model="updateFormControl.nameZhCn"
                v-validate="{ required: false, max: 100 }"
                :state="validateState('nameZhCn')"
                aria-describedby="input-nameZhCn-live-feedback"
                data-vv-as="nameZhCn"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="inputPassword" class="text-brown">貨幣 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <v-select
                label="title"
                name="currency"
                :options="currencies"
                :reduce="currency => currency.currency"
                v-bind:class="{ 'border border-danger': veeErrors.has('currency') }"
                v-model="updateFormControl.currency"
                v-validate="{ required: true }"
                :state="validateState('currency')"
                aria-describedby="input-currency-live-feedback"
                data-vv-as="currency"
              >
                <template slot="option" slot-scope="option" class="form-select">
                  <span class="mr-2" v-html="option.icon"></span>
                  {{ option.title }}
                </template>
              </v-select>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="buyPrice" class="text-brown">買入 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="buyPrice"
                class="rounded-0"
                name="buyPrice"
                v-model="updateFormControl.buyPrice"
                v-validate="{ required: true, decimal:2, max: 100 }"
                :state="validateState('buyPrice')"
                aria-describedby="input-buyPrice-live-feedback"
                data-vv-as="buyPrice"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="sellPrice" class="text-brown">賣出 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="sellPrice"
                class="rounded-0"
                name="sellPrice"
                v-model="updateFormControl.sellPrice"
                v-validate="{ required: true, decimal:2, max: 100 }"
                :state="validateState('sellPrice')"
                aria-describedby="input-sellPrice-live-feedback"
                data-vv-as="sellPrice"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-3">
          <b-col sm="4">
            <label for="posId" class="text-brown">發送通知</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="updateFormControl.sendNotification"
              :checked="updateFormControl.sendNotification"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideEditModal()" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner>儲存
          </b-button>
        </div>
      </b-form>
    </b-modal>

  </b-container>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  getGoldPrices,
  getGoldPrice,
  createGoldPrice,
  updateGoldPrice,
  deactivateGoldPrice,
  activateGoldPrice,
  deleteGoldPrice
} from '@/services/goldnews.service'
import IconHongKongDollar from '@/components/icons/IconHongKongDollar.vue'
import IconYuan from '@/components/icons/IconYuan.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import TableData from '../components/TableData.vue'

export default {
  name: 'GoldNews',

  components: {
    IconHongKongDollar,
    IconYuan,
    'v-select': vSelect,
    TableData
  },

  data: () => ({
    goldNewsId: 0,
    currencies: [
      {
        title: '人民幣',
        currency: 'RMB',
        icon: `<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7595 21.9688C5.71127 21.9688 0.790771 17.0483 0.790771 11C0.790771 4.95175 5.71127 0.03125 11.7595 0.03125C17.8078 0.03125 22.7283 4.95175 22.7283 11C22.7283 17.0483 17.8078 21.9688 11.7595 21.9688ZM11.7595 0.4375C5.93512 0.4375 1.19702 5.17559 1.19702 11C1.19702 16.8244 5.93512 21.5625 11.7595 21.5625C17.5839 21.5625 22.322 16.8244 22.322 11C22.322 5.17559 17.5839 0.4375 11.7595 0.4375Z" fill="#37474F"/>
                <path d="M11.7595 20.3438C6.60705 20.3438 2.41577 16.1525 2.41577 11C2.41577 5.84753 6.60705 1.65625 11.7595 1.65625C16.912 1.65625 21.1033 5.84753 21.1033 11C21.1033 16.1525 16.912 20.3438 11.7595 20.3438ZM11.7595 2.0625C6.8313 2.0625 2.82202 6.07178 2.82202 11C2.82202 15.9282 6.8313 19.9375 11.7595 19.9375C16.6877 19.9375 20.697 15.9282 20.697 11C20.697 6.07178 16.6877 2.0625 11.7595 2.0625Z" fill="#37474F"/>
                <path d="M12.9783 17.9062H10.5408V15.0625H7.69707V13.4375H10.5408V13.0312H7.69707V11.4062H10.1923L6.73914 5.3125H9.65764L11.7596 10.0477L13.8619 5.3125H16.7804L13.3273 11.4062H15.8221V13.0312H12.9783V13.4375H15.8221V15.0625H12.9783V17.9062ZM10.9471 17.5H12.5721V14.6562H15.4158V13.8438H12.5721V12.625H15.4158V11.8125H12.6298L16.0829 5.71875H14.1264L11.7596 11.0492L9.39317 5.71875H7.43667L10.8898 11.8125H8.10332V12.625H10.9471V13.8438H8.10332V14.6562H10.9471V17.5Z" fill="#37474F"/>
              </svg>`
      },
      {
        title: '港元',
        currency: 'HKD',
        icon: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9999 21.5309C17.7114 21.5309 21.5307 17.7115 21.5307 13C21.5307 8.28861 17.7114 4.46924 12.9999 4.46924C8.28849 4.46924 4.46912 8.28861 4.46912 13C4.46912 17.7115 8.28849 21.5309 12.9999 21.5309Z" fill="#CEE8FA"/>
                <path d="M13 0C12.6108 0 12.2953 0.315555 12.2953 0.704693C12.2953 1.09383 12.6108 1.40939 13 1.40939C15.9425 1.40939 18.6319 2.51215 20.6781 4.32535L19.0134 5.99007C17.3402 4.55011 15.2307 3.76457 13 3.76457C10.7069 3.76457 8.60653 4.60495 6.99 5.99347L4.82651 3.82993C4.82255 3.82597 4.81829 3.82267 4.81433 3.81886C4.81082 3.8152 4.80783 3.81124 4.80427 3.80764C4.52919 3.53255 4.08287 3.53255 3.80774 3.80764C1.35226 6.26302 0 9.52765 0 13C0 20.1682 5.83173 25.9999 13 25.9999C16.4724 25.9999 19.7369 24.6477 22.1923 22.1923C24.6477 19.737 25.9999 16.4724 25.9999 13C26 5.83183 20.1682 0 13 0ZM18.5374 7.46958C18.8124 7.74497 19.2586 7.74532 19.5339 7.47019C19.5543 7.44978 19.5728 7.42819 19.5901 7.40605C19.5916 7.40458 19.5933 7.40331 19.5948 7.40184L21.6746 5.32194C23.342 7.20354 24.4079 9.62921 24.5685 12.2953H23.7845C23.3953 12.2953 23.0798 12.6109 23.0798 13C23.0798 13.3892 23.3953 13.7047 23.7845 13.7047H24.5687C24.414 16.3019 23.4057 18.7363 21.6786 20.6822L20.0064 19.01C21.395 17.3935 22.2353 15.2932 22.2353 13C22.2353 12.6109 21.9198 12.2953 21.5306 12.2953C21.1414 12.2953 20.8259 12.6109 20.8259 13C20.8259 17.3153 17.3152 20.8261 12.9998 20.8261C8.68447 20.8261 5.17371 17.3154 5.17371 13C5.17371 8.68468 8.68447 5.17391 12.9998 5.17391C15.0923 5.17396 17.0588 5.98915 18.5374 7.46958ZM13 23.0119C12.6108 23.0119 12.2953 23.3275 12.2953 23.7166V24.5686C9.62916 24.4079 7.20349 23.3421 5.32189 21.6748L6.99 20.0066C8.60653 21.3951 10.7068 22.2355 13 22.2355C15.2932 22.2355 17.3934 21.3951 19.0099 20.0066L20.6821 21.6787C18.7361 23.4058 16.3017 24.4141 13.7047 24.5689V23.7166C13.7047 23.3274 13.3892 23.0119 13 23.0119ZM1.43142 13.7047H3.79123C3.94414 15.7222 4.74846 17.5606 5.99347 19.0099L4.32535 20.6781C2.65805 18.7965 1.59215 16.3709 1.43142 13.7047ZM4.32134 5.31792L5.99347 6.99005C4.74846 8.43945 3.94419 10.2778 3.79123 12.2953H1.43117C1.58595 9.69817 2.59427 7.26382 4.32134 5.31792Z" fill="#2D527C"/>
                <path d="M15.7526 12.4394V10.5069C16.2298 10.6138 16.4849 10.8841 16.511 11.3111C16.5163 11.3961 16.5809 11.4627 16.6581 11.4627H17.4299C17.4679 11.4627 17.5039 11.4468 17.5314 11.4181C17.563 11.385 17.5799 11.3385 17.5776 11.2903C17.5276 10.1906 16.8629 9.46454 15.7526 9.29412V8.64986C15.7526 8.56023 15.6864 8.4873 15.605 8.4873H14.8333C14.7519 8.4873 14.6857 8.56023 14.6857 8.64986V9.29412C13.5234 9.47226 12.8577 10.2483 12.8577 11.4274C12.8577 12.6066 13.5234 13.3826 14.6857 13.5607V15.4931C14.2086 15.3863 13.9534 15.1159 13.9273 14.689C13.9221 14.604 13.8575 14.5374 13.7802 14.5374H13.0084C12.9705 14.5374 12.9345 14.5532 12.907 14.582C12.8754 14.615 12.8585 14.6616 12.8608 14.7097C12.9108 15.8095 13.5755 16.5355 14.6858 16.706V17.3502C14.6858 17.4399 14.752 17.5128 14.8334 17.5128H15.605C15.6864 17.5128 15.7527 17.4399 15.7527 17.3502V16.706C16.915 16.5278 17.5807 15.7518 17.5807 14.5726C17.5807 13.3935 16.915 12.6175 15.7526 12.4394ZM16.5138 14.5727C16.5138 14.7795 16.5139 15.3227 15.7526 15.4932V13.6522C16.5139 13.8227 16.5138 14.3659 16.5138 14.5727ZM13.9245 11.4275C13.9245 11.2207 13.9245 10.6775 14.6857 10.507V12.348C13.9245 12.1775 13.9245 11.6343 13.9245 11.4275Z" fill="#2D527C"/>
                <path d="M10.2957 15.4385L11.7816 13.858C11.8491 13.7858 11.8599 13.6823 11.8084 13.6006C11.7642 13.5304 11.681 13.4868 11.591 13.4868H10.9135C10.8399 13.4868 10.7683 13.517 10.722 13.5674L9.65656 14.7179V13.6521C9.65656 13.5281 9.54464 13.4272 9.40702 13.4272H8.86153C8.72391 13.4272 8.61194 13.5281 8.61194 13.6521V17.0969C8.61194 17.221 8.72391 17.322 8.86153 17.322H9.40702C9.54464 17.322 9.65656 17.221 9.65656 17.0969V16.1507L10.9064 17.258C10.9525 17.2988 11.0162 17.322 11.0811 17.322H11.7271C11.8189 17.322 11.9058 17.2747 11.9494 17.2005C11.9977 17.1144 11.9808 17.01 11.9068 16.9404L10.2957 15.4385Z" fill="#2D527C"/>
                <path d="M9.21439 12.5729C9.35201 12.5729 9.46393 12.4719 9.46393 12.3478V11.0842H10.7067V12.3478C10.7067 12.4719 10.8186 12.5729 10.9562 12.5729H11.5017C11.6394 12.5729 11.7513 12.4719 11.7513 12.3478V8.90308C11.7513 8.77913 11.6394 8.67822 11.5017 8.67822H10.9562C10.8186 8.67822 10.7067 8.77907 10.7067 8.90308V10.1669H9.46393V8.90308C9.46393 8.77913 9.35201 8.67822 9.21439 8.67822H8.6689C8.53128 8.67822 8.41931 8.77907 8.41931 8.90308V12.3478C8.41931 12.4719 8.53128 12.5729 8.6689 12.5729H9.21439Z" fill="#2D527C"/>
              </svg>`
      }
    ],
    filters: {
      name: '',
      start: '',
      end: '',
      size: 10,
      page: 0
    },
    goldPrices: [],
    createFormControl: {
      active: true,
      nameZhCn: '',
      nameZhHk: '',
      currency: '',
      buyPrice: null,
      sellPrice: null
    },
    updateFormControl: {
      active: true,
      nameZhCn: '',
      nameZhHk: '',
      currency: '',
      buyPrice: null,
      sellPrice: null,
      sendNotification: false
    },
    headers: [
      {
        text: '飾金產品編號',
        value: 'id'
      },
      {
        text: '飾金產品',
        value: 'nameZhHk'
      },
      {
        text: '饰金产品 (简体版本)',
        value: 'nameZhCn'
      },
      {
        text: '賣出',
        value: 'sellPrice'
      },
      {
        text: '買入',
        value: 'buyPrice'
      },
      {
        text: '貨幣',
        value: 'currency'
      },
      {
        text: '狀態',
        value: 'active'
      },
      {
        text: '創建日期',
        value: 'createdDate'
      },
      {
        text: '操作',
        value: 'action'
      }
    ],
    isLoading: false,
    isSubmitting: false
  }),

  mounted () {
    this.getAll()
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  methods: {
    async getAll () {
      this.isLoading = true
      await getGoldPrices(this.filters).then(
        res => {
          this.goldPrices = res.data.data
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.isLoading = false
    },
    search: debounce(function () {
      this.getAll()
    }, 250),
    handlePaginate (pageNo) {
      this.filters.page = pageNo
      getGoldPrices(this.filters).then(
        res => {
          this.goldPrices = res.data.data
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    onSubmitCreateGoldNews () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        createGoldPrice(this.createFormControl).then(
          res => {
            if (res.data.success) {
              this.hideCreateModal()
              this.getAll()
              this.makeToast('success', '飾金金價', '儲存成功')
              this.createFormControl = {
                active: true,
                nameZhCn: '',
                nameZhHk: '',
                currency: '',
                buyPrice: null,
                sellPrice: null
              }
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
      })
    },
    async onSubmitUpdateGoldNews () {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          return
        }
        this.isSubmitting = true
        await updateGoldPrice(this.goldNewsId, this.updateFormControl).then(
          res => {
            if (res.data.success) {
              this.hideEditModal()
              this.getAll()
              this.makeToast('success', '飾金金價', '更新成功')
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
        this.isSubmitting = false
      })
    },
    handleDeactivate (id) {
      deactivateGoldPrice(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '飾金金價', '停用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleActivate (id) {
      activateGoldPrice(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '飾金金價', '啟用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleDelete (id) {
      deleteGoldPrice(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '飾金金價', '刪除成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },

    showCreateModal () {
      this.$refs.createGoldNewsModal.show()
    },
    hideCreateModal () {
      this.resetformControl()
      this.$refs.createGoldNewsModal.hide()
    },
    showEditModal (id) {
      getGoldPrice(id).then(
        res => {
          if (res.data.success) {
            this.goldNewsId = id
            this.updateFormControl = {
              active: res.data.data.active,
              nameZhCn: res.data.data.nameZhCn,
              nameZhHk: res.data.data.nameZhHk,
              currency: res.data.data.currency,
              buyPrice: res.data.data.buyPrice,
              sellPrice: res.data.data.sellPrice,
              sendNotification: false
            }
            this.$refs.editGoldNewsModal.show()
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    hideEditModal () {
      this.goldNewsId = 0
      this.resetformControl()
      this.$refs.editGoldNewsModal.hide()
    },
    resetformControl () {
      this.formControl = {
        active: true,
        nameZhCn: '',
        nameZhHk: '',
        currency: '',
        buyPrice: null,
        sellPrice: null
      }
    }
  }
}
</script>

<style lang="scss">
.v-select {
  .vs__dropdown-toggle {
    height: 38px;
    border-radius: 0;
  }
}
</style> -->
