import http from './http-common'

export async function getGoldPrices (filters) {
  return await http.get(`/staff/goldprices?sort=createdDate,desc&name=${filters.name}&start=${filters.start}&end=${filters.end}&size=${filters.size}&page=${filters.page}`)
}

export async function getGoldPrice (id) {
  return await http.get(`/staff/goldprices/${id}`)
}

export async function createGoldPrice (data) {
  return await http.post('/staff/goldprices', data)
}

export async function updateGoldPrice (id, data) {
  return await http.put(`/staff/goldprices/${id}`, data)
}

export async function deactivateGoldPrice (id) {
  return await http.put(`/staff/goldprices/${id}/deactivate`)
}

export async function activateGoldPrice (id) {
  return await http.put(`/staff/goldprices/${id}/activate`)
}

export async function deleteGoldPrice (id) {
  return await http.delete(`/staff/goldprices/${id}`)
}
